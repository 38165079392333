import React from "react";

import { PortableText } from "@portabletext/react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";

import ServiceLogo from "../../components/ServiceLogo/ServiceLogo";
import Tag from "../../components/Tag/Tag";
import Umbrella from "../../components/Umbrella/Umbrella";
import UmbrellaContent from "../../components/UmbrellaContent/UmbrellaContent";
import AllComponents from "../content/AllComponents";
import Header from "../layouts/Header";
import MainLayout from "../layouts/MainLayout";
import Seo from "../Seo/Seo";

const ServiceDetailPage = ({ data: { page } }) => (
  <MainLayout
    variant={page.variant}
    header={(
      <Header
        text={<PortableText value={page._rawDescription} />}
        logo={<ServiceLogo variant={page.variant} white />}
        headline={page.title}
        variant="service"
        theme={page.variant.toLowerCase().replace(" ", "")}
        bgVideo={page.variant.toLowerCase().replace(" ", "")}
        umbrella={(
          <div className="grid grid-cols-12 gap-x-30">
            <div className="col-span-12 lg:col-span-8">
              <UmbrellaContent umbrella={<Umbrella label="What we do" color="white" />}>
                {page.tagList?.tags?.map((tag) => (
                  <Tag key={tag.id} white label={tag.label} />
                ))}
              </UmbrellaContent>
            </div>
          </div>
        )}
      />
    )}
  >
    {page.contentElements && (
      <AllComponents elements={page.contentElements} />
    )}
  </MainLayout>
);

export const query = graphql`
  query ($id: String!) {
    page: sanityServiceDetail(id: {eq: $id}) {
      id
      Metadata {
        ...MetaData
      }
      tagList {
        tags {
          url
          label
        }
        useJumpmarks
      }
      title
      slug {
        current
      }
      variant
      headline
      _rawDescription
      contentElements {
        ...SectionHeader
        ...ServiceCardList
        ...SolutionCardList
        ...OfficeCardList
        ...ContentCardList
        ...ContentCardGrid 
        ...CtaCard
        ...ContentModuleAccordion
        ...ContentSection
      }
    }
 }
`;

ServiceDetailPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const seo = ({ data }) => (
  <Seo data={data.page?.Metadata} />
);

seo.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export const Head = seo;

export default ServiceDetailPage;
